import cx from "classnames";

import styles from "./styles.module.scss";

export const DotButton = ({ selected, onClick }) => (
	<button
		className={cx(styles["embla__dot"], {
			[styles["is-selected"]]: selected,
		})}
		type="button"
		onClick={onClick}
	/>
);

export const PrevButton = ({ enabled, onClick }) => (
	<button
		className={cx(styles["embla__button"], styles["embla__button--prev"])}
		onClick={onClick}
		disabled={!enabled}
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			stroke="currentColor"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M15.75 19.5L8.25 12l7.5-7.5"
			/>
		</svg>
	</button>
);

export const NextButton = ({ enabled, onClick }) => (
	<button
		className={cx(styles["embla__button"], styles["embla__button--next"])}
		onClick={onClick}
		disabled={!enabled}
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			stroke="currentColor"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M8.25 4.5l7.5 7.5-7.5 7.5"
			/>
		</svg>
	</button>
);
