import Image from "next/image";
import Headline from "@tpg/components/Headline";

import styles from "./styles.module.scss";

const Hero = ({ slice }) => {
	const {
		hero_bar_color,
		hero_cta_link,
		hero_cta_text,
		hero_description,
		hero_image: { url },
		hero_subtitle,
		hero_title,
	} = slice.primary;

	const justHeadline = hero_subtitle.length === 0;
	const hasCTA = hero_cta_link && hero_cta_text;

	const cta = {
		url: hero_cta_link.url,
		label: hero_cta_text,
	};

	return (
		<section>
			<div className="container">
				<Headline
					{...{
						cta,
						hasCTA,
						justHeadline,
					}}
					description={hero_description}
					headline={hero_title}
					lineColor={hero_bar_color}
					subHeadline={hero_subtitle}
				/>
			</div>

			<div className={styles["hero"]}>
				<div className={styles["hero-image"]}>
					<Image alt={hero_title} height={916} src={url} width={1116} />
				</div>
			</div>
		</section>
	);
};

export default Hero;
