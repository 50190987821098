import classnames from "classnames";
import { ComponentPropsWithoutRef } from "react";

import styles from "./styles.module.scss";

type LinkProps = ComponentPropsWithoutRef<"a"> & {
	theme?: string;
};

const LinkButton = ({ children, href, theme, type }: LinkProps) => (
	<a href={href} className={classnames(styles.btn, theme)} {...{ type }}>
		{children}
	</a>
);

export default LinkButton;
