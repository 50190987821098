/* eslint-disable @next/next/no-img-element */
import { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { DotButton, NextButton, PrevButton } from "./EmblaCarouselButtons";

import styles from "./styles.module.scss";

const Carousel = ({ slice }) => {
	const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [scrollSnaps, setScrollSnaps] = useState([]);
	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
	const scrollTo = useCallback(
		(index) => embla && embla.scrollTo(index),
		[embla]
	);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setSelectedIndex(embla.selectedScrollSnap());
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla, setSelectedIndex]);

	useEffect(() => {
		if (!embla) return;
		onSelect();
		setScrollSnaps(embla.scrollSnapList());
		embla.on("select", onSelect);
	}, [embla, setScrollSnaps, onSelect]);

	return (
		<section className={styles.carousel}>
			<div className={styles.embla}>
				<div className={styles["embla__viewport"]} ref={viewportRef}>
					<div className={styles["embla__container"]}>
						{slice.items?.map((item, i) => (
							<div key={i} className={styles["embla__slide"]}>
								<div className={styles["embla__slide__inner"]}>
									<img
										alt="Carousel image"
										className={styles["embla__slide__img"]}
										src={item.carousel_image.url}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
				<PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
				<NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
			</div>
			<div className={cx(styles["embla__dots"])}>
				{scrollSnaps.map((_, index) => (
					<DotButton
						key={index}
						selected={index === selectedIndex}
						onClick={() => scrollTo(index)}
					/>
				))}
			</div>
		</section>
	);
};

export default Carousel;
