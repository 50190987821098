import Image from "next/image";

import styles from "./styles.module.scss";

const Banner = ({ slice }) => (
	<section className={styles.banner}>
		<div className={styles["banner-content"]}>
			{slice.variation === "bannerWithVideo" && (
				<>
					{slice.primary.banner_video.url && (
						<video
							autoPlay
							loop
							playsInline
							poster={
								slice.primary.banner_video_poster
									? slice.primary.banner_video_poster.url
									: ""
							}
							muted
							src={slice.primary.banner_video.url}
						/>
					)}

					{slice.primary.banner_graphic.url && (
						<div className={styles["banner-image"]}>
							<Image
								alt={slice.primary.banner_graphic.alt}
								height={slice.primary.banner_graphic.dimensions.height}
								src={slice.primary.banner_graphic.url}
								width={slice.primary.banner_graphic.dimensions.width}
							/>
						</div>
					)}
					{slice.primary.title && (
						<h1
							dangerouslySetInnerHTML={{
								__html: slice.primary.title[0].text,
							}}
						/>
					)}
				</>
			)}
		</div>
	</section>
);

export default Banner;
