import cx from "classnames";
import Image from "next/image";

import ContactForm from "@tpg/components/ContactForm";
import Headline from "@tpg/components/Headline";

import styles from "./styles.module.scss";

const ContactSection = ({ slice }) => {
	const { contact_headline, contact_image, contact_title } = slice.primary;

	switch (slice.variation) {
		case "contactWithImage":
			return (
				<div
					className={cx(styles["contact-footer"], {
						[styles["contact-footer-columns"]]:
							slice.variation === "contactWithImage",
					})}
				>
					{contact_headline.length > 0 && (
						<Headline
							className={styles["contact-headline"]}
							headline={contact_headline}
							justHeadline
							lineColor={"burnt-yellow"}
						/>
					)}
					{contact_image && (
						<div className={styles["contact-image"]}>
							<Image
								alt={contact_image.alt}
								layout="fill"
								objectFit="cover"
								src={contact_image.url}
							/>
						</div>
					)}

					<ContactForm legend={contact_title[0].text} />
				</div>
			);
		default:
			return (
				<div className={"container"}>
					<div
						className={cx(styles["contact-footer"], {
							[styles["contact-footer-columns"]]:
								slice.variation === "contactWithImage",
						})}
					>
						{contact_headline.length > 0 && (
							<Headline
								className={styles["contact-headline"]}
								headline={contact_headline}
								justHeadline
								lineColor={"burnt-yellow"}
							/>
						)}
						{contact_image && (
							<div className={styles["contact-image"]}>
								<Image
									alt={contact_image.alt}
									layout="fill"
									objectFit="cover"
									src={contact_image.url}
								/>
							</div>
						)}

						<ContactForm legend={contact_title[0].text} />
					</div>
				</div>
			);
	}
};

export default ContactSection;
