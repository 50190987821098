import cx from "classnames";
import Image from "next/image";
import { PrismicRichText } from "@prismicio/react";

import styles from "./styles.module.scss";

const SectionBlock = ({ slice }) => {
	switch (slice.variation) {
		case "sectionBlockWith2Column":
			const sliceImageContain = slice.primary.background_image_fit;

			return (
				<section
					className={cx(
						styles["section-block"],
						styles["section-block-columns"]
					)}
				>
					<div
						className={cx(styles["section-content"], {
							[styles["bg-transparent"]]:
								slice.primary.section_background_color === "Transparent",
						})}
					>
						<div className="container">
							{slice.primary.title && (
								<h1
									dangerouslySetInnerHTML={{
										__html: slice.primary.title[0].text,
									}}
								/>
							)}
							{slice.primary.description && (
								<PrismicRichText field={slice.primary.description} />
							)}
						</div>
					</div>
					{slice.primary.background_image.url && (
						<div className={styles["section-content-image"]}>
							<Image
								alt={slice.primary.background_image.alt}
								layout="fill"
								objectFit={sliceImageContain ? sliceImageContain : "cover"}
								src={slice.primary.background_image.url}
							/>
						</div>
					)}
				</section>
			);
		case "sectionBlockImageOnly":
			return (
				<section
					className={cx(
						styles["section-block"],
						styles["section-block-image-only"]
					)}
				>
					{slice.primary.background_image.url && (
						<div className={styles["section-content-image"]}>
							<Image
								alt={slice.primary.background_image.alt}
								layout="fill"
								objectFit="contain"
								src={slice.primary.background_image.url}
							/>
						</div>
					)}
				</section>
			);

		case "sectionBlockWithStats":
			const items = slice.items[0].hasOwnProperty(
				"stat_value" && "stat_description"
			)
				? slice.items
				: null;
			return (
				<section className={cx(styles["section-block"])}>
					<div className={styles["section-content-stats"]}>
						{items.map((item, i) => (
							<div className={styles.stat} key={i}>
								<p>{item.stat_value}</p>
								<p>{item.stat_description}</p>
							</div>
						))}
					</div>
				</section>
			);
		default:
			return (
				<section className={cx(styles["section-block"])}>
					{slice.primary.background_image.url && (
						<div
							className={styles["section-bg-image"]}
							style={{
								backgroundImage: `url(${slice.primary.background_image.url}) `,
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								height: "100%",
								position: "absolute",
								width: "100%",
							}}
						></div>
					)}

					<div
						className={cx(
							styles["section-content"],
							"container",
							styles["section-content-default"]
						)}
					>
						{slice.primary.title && (
							<PrismicRichText field={slice.primary.title} />
						)}
						{slice.primary.description && (
							<PrismicRichText field={slice.primary.description} />
						)}
					</div>
				</section>
			);
	}
};

export default SectionBlock;
