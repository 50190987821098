import cx from "classnames";
import Card from "@tpg/components/Card";

import styles from "./styles.module.scss";

const CardList = ({ slice: { items, variation } }) => (
	<section>
		<div className="container">
			<div className={cx(styles["card-list"])}>
				{items.map((card, i) => (
					<Card
						{...{
							...card,
							variation,
						}}
						key={i}
					/>
				))}
			</div>
		</div>
	</section>
);

export default CardList;
