import cx from "classnames";
import { PrismicRichText } from "@prismicio/react";

import { CTA } from "@tpg/components/Card";
import LinkButton from "@tpg/components/LinkButton";

import styles from "./styles.module.scss";

interface HeadlineProps {
	className?: string;
	cta?: CTA;
	description?: [];
	hasCTA?: boolean;
	headline: [];
	headlineClassName?: string;
	justHeadline?: boolean;
	lineColor: string;
	subHeadline?: [];
	url?: string;
}
const Headline = ({
	className,
	cta,
	description,
	hasCTA,
	headline,
	justHeadline = false,
	lineColor,
	subHeadline,
}: HeadlineProps) => (
	<div
		className={cx(
			styles.headline,
			justHeadline ? styles["justHeadline"] : "",
			className
		)}
	>
		<div className={cx(styles.content, styles[`line--color-${lineColor}`])}>
			{justHeadline ? (
				<>
					<PrismicRichText field={headline} />
					{hasCTA && <LinkButton href={cta?.url}>{cta?.label}</LinkButton>}
				</>
			) : (
				<>
					{headline && <PrismicRichText field={headline} />}
					{subHeadline && <PrismicRichText field={subHeadline} />}
					{description && <PrismicRichText field={description} />}
				</>
			)}
		</div>
		{hasCTA && !justHeadline && (
			<>
				<LinkButton href={cta?.url}>{cta?.label}</LinkButton>
			</>
		)}
	</div>
);

export default Headline;
