// This file is generated by Slice Machine. Update with care!

import Banner from './Banner';
import Blurb from './Blurb';
import CardList from './CardList';
import Carousel from './Carousel';
import ContactSection from './ContactSection';
import Hero from './Hero';
import LogosSection from './LogosSection';
import SectionBlock from './SectionBlock';

export {
	Banner,
	Blurb,
	CardList,
	Carousel,
	ContactSection,
	Hero,
	LogosSection,
	SectionBlock,
};

export const components = {
	banner: Banner,
	blurb: Blurb,
	card: CardList,
	carousel: Carousel,
	contact_section: ContactSection,
	hero: Hero,
	logos_section: LogosSection,
	section_block: SectionBlock,
};
