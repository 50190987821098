import cx from "classnames";
import Image from "next/image";
import { PrismicRichText } from "@prismicio/react";

import { isEmpty } from "@tpg/helpers";
import styles from "./styles.module.scss";

const Blurb = ({ className, slice }) => {
	const {
		blurb_alignment,
		blurb_image_classname,
		blurb_list: items,
		content,
		cta,
		footnote,
		image,
		subtitle,
		title,
	} = slice.primary;

	const blurbAlign = blurb_alignment.toLowerCase().replace(" ", "-");
	const hasImage = !isEmpty(image);
	const hasList = items.length > 0;

	return (
		<section>
			<div className={cx(styles["blurb"], styles[blurbAlign])}>
				<div className={cx("container", styles["blurb-content"])}>
					<div
						className={cx(
							styles["blurb-body"],
							{
								[styles["services-body"]]: hasList,
							},
							className
						)}
					>
						{hasList ? (
							<ul className={styles["list"]}>
								{items.map((item, i) => (
									<li key={i}>{item.text}</li>
								))}
							</ul>
						) : (
							<>
								{title && <PrismicRichText field={title} />}
								{subtitle && <PrismicRichText field={subtitle} />}
								{content && <PrismicRichText field={content} />}
								{footnote && (
									<div className={styles["footnote"]}>
										<PrismicRichText field={footnote} />
									</div>
								)}
							</>
						)}
						{cta && (
							<div className={styles.btn}>
								<PrismicRichText field={cta} />
							</div>
						)}
					</div>
					{hasImage && (
						<div className={styles[blurb_image_classname]}>
							<Image
								alt={image.alt}
								height={image.dimensions.height}
								layout="responsive"
								objectFit="contain"
								src={image.url}
								width={image.dimensions.width}
							/>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default Blurb;
