import cx from "classnames";
import Image from "next/image";
import { PrismicRichText } from "@prismicio/react";

import LinkButton from "@tpg/components/LinkButton";

import styles from "./styles.module.scss";

type CardImage = {
	alt: string;
	url: string;
};

export type CTA = {
	label: string;
	url: string;
};
export interface CardProps {
	card_content: [];
	card_cta_type: string;
	card_cta_link: { url: string };
	card_cta_text: string;
	card_image: CardImage;
	card_image_type: string;
	card_title: [{ text: string }];
	card_title_underline_color?: string;
	card_underline_color: string;
	variation: string;
}

const Card = ({ ...props }: CardProps) => {
	const {
		card_content,
		card_cta_link,
		card_cta_text,
		card_cta_type,
		card_image: { alt, url },
		card_image_type,
		card_title,
		card_title_underline_color,
	} = props;

	const isIcon = card_image_type === "Icon";
	const isButton = card_cta_type === "Button";
	const hasCTA = card_cta_link && card_cta_text;

	return (
		<div className={styles.card}>
			{isIcon ? (
				<Image alt={alt} height={130} src={url} width={130} />
			) : (
				<div className={styles["card-image"]}>
					<Image alt={alt} layout="fill" objectFit="contain" src={url} />
				</div>
			)}
			<h2
				className={cx(
					"card_title",
					styles[`underline-${card_title_underline_color}`],
					isIcon ? [styles.block] : [styles.inline]
				)}
			>
				{card_title.length > 0 && card_title[0].text}
			</h2>
			<div className={styles["card-body"]}>
				{card_content && <PrismicRichText field={card_content} />}
			</div>
			<div>
				{hasCTA && (
					<>
						{isButton ? (
							<LinkButton href={card_cta_link.url}>{card_cta_text}</LinkButton>
						) : (
							<a className={styles["card-link"]} href={card_cta_link.url}>
								{card_cta_text}
							</a>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Card;
