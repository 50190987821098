import { useState } from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import Button from "../Button";
import styles from "./styles.module.scss";

interface ContactFormProps {
	legend: string;
}
const ContactForm = ({ legend }: ContactFormProps) => {
	const [successMessage, setSuccessMessage] = useState(false);

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const router = useRouter();

	const onSubmit = handleSubmit(async (data) => {
		const { businessName, email, message, name } = data;

		await fetch("/api/send-email", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				businessName,
				email,
				message,
				name,
			}),
		}).then((res) => {
			if (res.ok) {
				reset();
				setSuccessMessage(true);

				setTimeout(() => {
					router.reload();
				}, 3000);
			}
		});
	});

	return (
		<form className={styles["tpg-form"]} onSubmit={onSubmit}>
			{successMessage && <p className="success">Your email has been sent! </p>}
			<legend>
				{legend ? (
					<h1 dangerouslySetInnerHTML={{ __html: legend }} />
				) : (
					"Contact Us"
				)}
			</legend>

			<div>
				<input
					placeholder="email"
					type="email"
					{...register("email", { required: true })}
				/>
				<p className={styles["error"]}>{errors.email && "Email is required"}</p>
			</div>
			<div>
				<input
					placeholder="name"
					type="text"
					{...register("name", { required: true })}
				/>
				<p className={styles["error"]}>{errors.name && "Name is required"}</p>
			</div>
			<div>
				<input
					placeholder="business name"
					type="text"
					{...register("businessName", { required: true })}
				/>
				<p className={styles["error"]}>
					{errors.businessName && "Business name is required"}
				</p>
			</div>
			<div>
				<input
					placeholder="message"
					type="text"
					{...register("message", { required: true })}
				/>
				<p className={styles["error"]}>
					{errors.message && "Message is required"}
				</p>
			</div>
			<div>
				<p className={styles["policy"]}>
					By submitting this form, you confirm you have read and acknowledge The
					Parnell Group’s <a>privacy policy.</a>
				</p>
			</div>
			<div>
				<Button type="submit">submit</Button>
			</div>
		</form>
	);
};

export default ContactForm;
