/* eslint-disable @next/next/no-img-element */
import cx from "classnames";

import { PrismicRichText } from "@prismicio/react";
import { isEmpty } from "@tpg/helpers";

import styles from "./styles.module.scss";

const LogosSection = ({ slice }) => {
	const {
		items,
		primary: { description, grayscale, title },
	} = slice;

	const filteredItems = items.filter((item) => !isEmpty(item.image));

	return (
		<div className={cx("container", styles["logo-sections"])}>
			<section>
				{title && <PrismicRichText field={title} />}
				{description && <div>{<PrismicRichText field={description} />}</div>}
				{filteredItems.length > 0 && (
					<ul>
						{filteredItems.map((item, i) => {
							const {
								image: { alt, url: src },
							} = item;

							return (
								<li key={i}>
									{grayscale ? (
										<div className={styles.grayscale}>
											<img alt={alt} className={styles["logo-img"]} src={src} />
										</div>
									) : (
										<img alt={alt} className={styles["logo-img"]} src={src} />
									)}
								</li>
							);
						})}
					</ul>
				)}
			</section>
		</div>
	);
};

export default LogosSection;
