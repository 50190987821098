import { ComponentPropsWithoutRef } from "react";

import styles from "./styles.module.scss";

type ButtonProps = ComponentPropsWithoutRef<"button">;

const Button = ({ children, type }: ButtonProps) => (
	<button className={styles.btn} {...{ type }}>
		{children}
	</button>
);

export default Button;
